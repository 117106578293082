import React from 'react'
import {Link} from 'gatsby'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

const BlogArticleSocial = (props) => {
    const { slug } = props;
    const siteURL = 'https://bryanlokey.com'
    const encodedURL = encodeURIComponent(`${siteURL}${slug}`);
    return (
      <div className="article-share">
          <ul className="social">
              <li><span>Share:</span></li>
              <li>
                  <Link to={`https://twitter.com/share/?url=${encodedURL}`} className="twitter" target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faTwitter} style={{width: '20px', height: '20px' }}/>
                  </Link>
              </li>
              <li>
                  <Link to={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedURL}`} className="linkedin" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faLinkedinIn} style={{width: '20px', height: '20px' }}/>
                  </Link>
              </li>
          </ul>
      </div>
    )
}

export default BlogArticleSocial
