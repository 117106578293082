import React from 'react'
import {Link} from 'gatsby'

const buildTags = tags => (
    tags.map(tag => (
        <Link to={`/tag/${tag.fieldValue.replace(/\s+/g, '-').toLowerCase()}`}>
            {tag.fieldValue} <span className="tag-link-count">({tag.totalCount})</span>
        </Link>
    ))
);

const BlogTags = (props) => {
    const { tags } = props;
    return (
      <div className="widget widget_tag_cloud">
          <h3 className="widget-title">Popular Tags</h3>
          <div className="tagcloud">
              {buildTags(tags)}
          </div>
      </div>
    )
}

export default BlogTags



