import React from "react"
import Layout from "../components/App/Layout"
import SEO from '../components/App/SEO'
import NavbarDynamic from "../components/App/NavbarDynamic"
import PageBanner from '../components/Common/PageBanner'
import FooterMinimal from "../components/App/FooterMinimal"
import BlogDetailsContent from '../components/Blog/BlogDetailsContent'
import { graphql } from "gatsby"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark, allMarkdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const blogPosts = allMarkdownRemark.recentPosts.slice(0, 3);
  const headerInfo = {
    title: `${frontmatter.title} | BryanLokey.com`,
    description: frontmatter.description,
    keywords: frontmatter.keywords,
    canonical: `https://bryanlokey.com${frontmatter.slug}`,
    ogImage: frontmatter.image.publicURL,
  };
  return (
    <div>
      <SEO {...headerInfo}/>
      <Layout>
        <NavbarDynamic />
        <PageBanner
            pageTitle="Blog Details"
            homePageText="Blog"
            homePageUrl="/blog/"
            activePageText="Blog Details"
        />
        {/* <BlogDetailsContent { ...frontmatter } categories={categoryList} tags={tagsList} posts={recentPosts} html={html}/> */}
        <BlogDetailsContent { ...frontmatter } { ...allMarkdownRemark } html={html} blogPosts={blogPosts} />
        <FooterMinimal />
      </Layout>
    </div>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      tagsList:
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
      categoryList:
          group(field: frontmatter___category) {
            fieldValue
            totalCount
          }
      recentPosts:
          edges {
            node {
              id
              excerpt(pruneLength: 250)
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                slug
                title
                image  {
                  publicURL
                }
                description
                tags
              }
            }
          }
      }
      markdownRemark(id: { eq: $id }) {
        html
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          slug
          title
          description
          image  {
            publicURL
          }
          category
          tags
          keywords
        }
      }
  }
`
