import React from 'react'
import {Link} from 'gatsby'

const buildBlogList = articles => (
  articles.map(article => (
    <article className="item">
        <Link to={article.node.frontmatter.slug} className="thumb">
            <span className="fullimage cover" role="img" style={{backgroundImage: `url(${article.node.frontmatter.image.publicURL})`}}></span>
        </Link>
        <div className="info">
            <span>
              {article.node.frontmatter.date}
            </span>
            <h4 className="title small">
                <Link to={article.node.frontmatter.slug}>
                  {article.node.frontmatter.title}
                </Link>
            </h4>
        </div>

        <div className="clear"></div>
    </article>
  ))
);

const BlogRecentPosts = (props) => {
    const { articles } = props;
    return (
      <div className="widget widget_tracer_posts_thumb">
          <h3 className="widget-title">Recent Posts</h3>
          {buildBlogList(articles)}
      </div>
    )
}

export default BlogRecentPosts
