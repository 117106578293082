import React from 'react'
import {Link} from 'gatsby'

const BlogArticleMeta = (props) => {
    const { category, date } = props;
    return (
      <div className="entry-meta">
          <ul>
              <li>
                  <span>Category</span>
                  <Link to={`/category/${category.toLowerCase()}`}>
                      {category}
                  </Link>
              </li>
              <li>
                  <span>Last Updated</span>
                      {date}
              </li>
          </ul>
      </div>
    )
}

export default BlogArticleMeta
