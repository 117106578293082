import React from 'react'
import BlogArticleMeta from './BlogArticleMeta'
import BlogArticleFooter from './BlogArticleFooter'
import BlogSidebar from './BlogSidebar'

const BlogDetailsContent = (props) => {
    const { html, date, category, image, tags, slug, categoryList, blogPosts, tagsList } = props;
    const builtTags = tags.toString().split(',');
    return (
        <section className="blog-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="blog-details-desc">
                            <div className="article-image">
                                <img src={image.publicURL} alt="bb" />
                            </div>
                            <div className="article-content">
                                <BlogArticleMeta category={category} date={date} />
                                <div
                                  className="blog-post-content"
                                  dangerouslySetInnerHTML={{ __html: html }}
                                />
                            </div>
                            <BlogArticleFooter tags={builtTags} slug={slug} />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <BlogSidebar articles={blogPosts} categoryList={categoryList} tagsList={tagsList} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogDetailsContent
