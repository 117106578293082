import React from 'react'
import {Link} from 'gatsby'

const buildCategories = categories => (
    categories.map(category => (
        <li>
          <Link to={`/category/${category.fieldValue.toLowerCase()}`}>
              {category.fieldValue} <span className="post-count">({category.totalCount})</span>
          </Link>
        </li>
    ))
);

const BlogCategories = (props) => {
    const { categories } = props;
    return (
      <div className="widget widget_categories">
          <h3 className="widget-title">Categories</h3>
          <ul>
              {buildCategories(categories)}
          </ul>
      </div>
    )
}

export default BlogCategories
