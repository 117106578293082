import React from 'react'
import {Link} from 'gatsby'
import BlogArticleSocial from './BlogArticleSocial'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/pro-duotone-svg-icons";

const buildTags = tags => (
  tags.map((tag, index) => (
    <Link
      to="/blog"
      key={index}
    >
      {tag}
    </Link>
  ))
);

const BlogArticleFooter = (props) => {
    const { tags, slug } = props;
    return (
      <div className="article-footer">
          <div className="article-tags">
              <span>
                <FontAwesomeIcon icon={faTag} style={{width: '20px', height: '20px' }}/>
              </span>
              {buildTags(tags)}
          </div>
          <BlogArticleSocial slug={slug} />
      </div>
    )
}

export default BlogArticleFooter
