import React from 'react'
import BlogRecentPosts from './sidebar/BlogRecentPosts'
import BlogCategories from './sidebar/BlogCategories'
import BlogTags from './sidebar/BlogTags'

const BlogSidebar = (props) => {
    const { articles, categoryList, tagsList } = props;
    return (
        <div className="widget-area">
            <BlogRecentPosts articles={articles} />
            <BlogCategories categories={categoryList} />
            <BlogTags tags={tagsList} />
        </div>
    )
}

export default BlogSidebar
